<template>
  <!--对话总体-->
  <div class="mess">
    <!--用户列表-->
    <div class="mess_user_list">
      <!--用户本人-->
      <div class="user">
        <img
            src="./asset/kefu.png"
            alt=""
            style="width: 40px; height: 40px; border-radius: 50%"
        />
        <span>聊天室</span>
      </div>
      <!--其他用户或群-->
      <div class="user_list">
        <div
            v-for="(item, index) in userList"
            :key="index"
            class="user_list_item"
            @click="showmessdlog(item, index)"
            :style="{
            background: index === userIdActive ? '#c8c7c6' : '#ffffff',
          }"
            v-if="showItem(item)"
        >
          <span class="user_name">{{ item }}</span>
<!--          <div-->
<!--              class="unreadMessages"-->
<!--              v-if="item.unreadChat > 0 && userIdActive != item.id"-->
<!--          ></div>-->
        </div>
      </div>
    </div>
    <!--有对话时，对话框-->
    <div class="mess_dialog">
      <!--对话框头部-->
      <div class="dlog_header">
        <span style="margin-left: 25px">{{ acceptUser }}</span>
      </div>
      <!--对话框内容-->
      <div class="dlog_content" id="mess_content" @scroll="scrollToBottom">
        <div
            v-for="(item, index) in messnowList"
            :key="index"
            class="dlog_content_item"
            style="margin-left: 5px"
        >
          <div><span style="font-size: 14px"> ( {{ item.time }} ) </span></div>
          <div><span style="font-size: 14px"> ( {{ item.message }} ) </span></div>
          <!--其他用户的消息展示-->
<!--          <div v-if="item.msgType == 1" class="content_other">-->
<!--            <span style="font-size: 18px"-->
<!--            >{{ item.sendName }}-->
<!--              <span style="font-size: 14px"> ( {{ item.time }} ) </span></span-->
<!--            >-->
<!--            <p-->
<!--                style="margin: 0px; margin-top: 4px"-->
<!--                class="content_other_bgd"-->
<!--                v-if="item.msg"-->
<!--            >-->
<!--              {{ item.msg }}-->
<!--            </p>-->
<!--            <p>-->
<!--              <img-->
<!--                  v-if="item.imgUrl"-->
<!--                  :src="item.imgUrl"-->
<!--                  alt=""-->
<!--                  style="width: 120px; height: 120px; border: 1px solid #e5e5e5"-->
<!--              />-->
<!--            </p>-->
<!--          </div>-->
          <!--本用户的消息展示-->
<!--          <div v-else class="content_me">-->
<!--            <span style="font-size: 18px"-->
<!--            ><span style="font-size: 14px"> ( {{ item.time }} ) </span-->
<!--            >{{ item.sendName }}</span-->
<!--            >-->
<!--            <p-->
<!--                style="margin: 0px; margin-top: 4px"-->
<!--                class="content_me_bgd"-->
<!--                v-if="item.msg"-->
<!--            >-->
<!--              {{ item.msg }}-->
<!--            </p>-->
<!--            <p>-->
<!--              <img-->
<!--                  v-if="item.imgUrl"-->
<!--                  :src="item.imgUrl"-->
<!--                  alt=""-->
<!--                  style="width: 120px; height: 120px; border: 1px solid #e5e5e5"-->
<!--              />-->
<!--            </p>-->
<!--          </div>-->
        </div>
      </div>
      <!--对话框底部-->
      <div class="dlog_footer">
        <div class="footer_content">
          <div class="footer_content_menu">
            <el-dropdown placement="top-start">
              <span class="el-dropdown-link">
                <img
                    src="./asset/face.png"
                    alt=""
                    style="width: 26px; height: 26px; margin-top: 2px"
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <div class="emojis">
                  <span v-for="key in emojis" :key="key" @click="mess += key">{{
                      key
                    }}</span>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <el-input v-model="mess" type="textarea" :rows="5" />
          <el-button
              type="primary"
              style="float: right; margin-top: 5px"
              @click="Wssendmess"
          >发送</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emojis from "./asset/emoji.json";
import { Message } from "element-ui";

export default {
  name: "chat",
  data() {
    return {
      userId: "",
      nickname: this.$route.query.nickname ,
      userAvatar: "",
      userList: "",
      acceptUser: "",
      mess: "",
      messlst: [],
      messnowList: [],
      emojis,
      ws: null,
      userIdActive: "",
      page: 1,
      pageSize: 10,
      total: 0,
      imageUrl: "",
      uploaderUrl: "http://" + process.env.VUE_APP_URL_chat + "/chat/upload",
      chatInputOffset: {}, // 记录光标位置
      focusNode: {},
      focusOffset: {},
      wsAddress:'ws://47.96.18.208:8887',
      // wsAddress:'ws://localhost:8887',
      state: false, // 判断当前是否链接上服务器，防止重复连接
    };
  },
  created() {
    this.connectWebsocket() ;
  },
  mounted() {
    this.ws.addEventListener("open", this.handleWsOpen.bind(this), false);
    this.ws.addEventListener("close", this.handleWsClose.bind(this), false);
    this.ws.addEventListener("error", this.handleWsError.bind(this), false);
    this.ws.addEventListener("message", this.handleWsMessage.bind(this), false);
  },
  methods: {
    showmessdlog(item, index) {
      this.page = 1;
      this.userIdActive = index;
      this.acceptUser = item;
      // item.unreadChat = 0;
      this.chatList();
    },
     Wssendmess() {
      if (this.mess === "") {
        return;
      }
      this.ws.send(JSON.stringify({
        "code": "10002",
        "nickname": this.nickname,
        "type": '2',
        "content": this.mess,
      }));
      console.log("发送消息");
      // console.log(this.mess)
      this.mess = "" ;

    },
    handleWsOpen() {
      console.log("WebSocket连接成功");
      //获取用户列表
      this.state = this.ws.readyState == 1;
      this.ws.send(JSON.stringify({
        "code": "10001",
        "nickname": this.nickname
      }));
      this.joinGroup();
    },
    handleWsClose(e) {
      console.log("WebSocket2关闭", e);
    },
    handleWsError(e) {
      console.log("WebSocket2发生错误", e);
    },
    chatList() {
      //获取聊天记录
      //传用户id去获取
      // this.ws.send(xxx);
    },
    handleWsMessage(e) {
      // console.log("收到的消息") ;
      // console.log(e.data.message);
      // console.log("WebSocket2收到消息:", JSON.parse(e.data));
      const data = JSON.parse(e.data);
      // console.log(11)
      console.log(data.message)
      if(data.name == '系统消息'){
        // 将用户列表进行显示
        console.log(data.message)
        this.userList = JSON.parse(data.message) ;
        return ;
      }
      if(data.name == 'group'){
        console.log(data.message) ; // 提示加入成功
        return ;
      }
      //所有接收到的数据在这里处理，然后赋值渲染到页面上
      // console.log(11)
      // console.log(data.message)
      this.messnowList.push(data);
      //对话框默认滚动到底部
      let div = document.getElementById("mess_content");
      setTimeout(() => {
        div.scrollTop = div.scrollHeight;
      }, 100);
    },
    scrollToBottom(e) {
      //触底判断
      if (e.target.scrollTop == 0) {
        if (this.messnowList.length == this.total) {
          return;
        }
        this.page++;
        this.chatList();
      }
    },
    handleAvatarSuccess(res, file) {
      if (res.code !== 200) {
        console.log(res.msg);
        Message.error(this.$t("gameManager91"));
        return;
      }
      this.imageUrl = "blob" + res.data.image_url + " blob";
      //图片单独发送、没有显示在输入框（因为这个做法要复杂一点）
      // this.ws.send(XXX);
    },
    handleAvatarError(res) {
      Message.error(this.$t("gameManager91"));
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isLt2M;
    },
    // 链接 webscoket的方法
    connectWebsocket(){
      if(this.state){
        this.$message.error("不可以重复连接");
        return ;
      }
      // 打开一个websocket 链接
      this.ws = new WebSocket(this.wsAddress)
    },
    showItem(item){ // 显示进入聊天室的用户
      if(item === this.nickname){
        return false ;
      }
      return true;
    },
    // 加入聊天组
    joinGroup(){
      this.ws.send(JSON.stringify({
        "code": "10003",
        "nickname": this.nickname
      }));
    }
  }
};
</script>
<style scoped>
.mess {
  border-radius: 5px;
  background-clip: padding-box;
  width: 100%;
  height: calc(100vh - 50px);
  border: 1px #8a8282;
  box-shadow: 0 0 10px #9b9393;
  background-color: white;
  display: flex;
}
.mess_user_btn {
  width: 80px;
  height: 100%;
  border-right: 1px solid #cecdcd;
  position: relative;
  background: #d3d3d3;
}
.mess_icon_logo {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 40px;
}
.mess_user_list {
  width: 270px;
  height: 100%;
  background-color: #9f9c9c;
  border-right: 1px solid #cecdcd;
  background-color: #fbfcfc;
}
.mess_dialog {
  width: 100%;
  height: 100%;
  background-color: #fbfcfc;
}
.mess_dialog_false {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 750px;
}
.dlog_header {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #cecdcd;
  display: flex;
  align-items: center;
  font-size: 24px;
}
.dlog_content {
  width: 100%;
  height: 65%;
  border-bottom: 1px solid #cecdcd;
  overflow-y: auto;
  overflow-x: hidden;
}
.dlog_footer {
  width: 100%;
}
.footer_content {
  margin: 10px;
}
.footer_content_menu {
  display: flex;
  height: 30px;
  width: 100%;
  margin-bottom: 5px;
}
.footer_content_menu_img {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 28px;
  margin-left: 10px;
  cursor: pointer;
}
.user {
  height: 60px;
  width: 270px;
  padding-left: 10px;
  /*垂直居中*/
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cecdcd;
}
.user_list {
  /* height: 100%; */
  overflow-y: scroll;
}
.user_list_item {
  height: 60px;
  background-color: #ffffff;
  border-bottom: 1px solid #cecdcd;
  display: flex;
  align-items: center;
  font-size: 28px;
  cursor: pointer;
}
.user_name {
  margin-left: 14px;
}
.unreadMessages {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  margin-bottom: 14px;
  margin-left: 2px;
}
::-webkit-scrollbar {
  display: none;
}

.content_other {
  float: left;
  clear: both;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.content_other_bgd {
  background: #e3e1e1;
  border-radius: 6px;
  line-height: 34px;
  padding: 10px;
  max-width: 600px;
  white-space: pre-wrap;
}
.content_me {
  text-align: right;
  float: right;
  clear: both;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: end;
}
.content_me_bgd {
  background: #95ec69;
  border-radius: 6px;
  line-height: 34px;
  padding: 10px;
  max-width: 600px;
  text-align: left;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.emojis {
  width: 300px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 20px;
  margin-left: 6px;
}
.emojis span {
  display: block;
  border: 1px solid #e0e0e0;
  width: 30px;
  height: 30px;
  margin: 0 -1px -1px 0;
  text-align: center;
  cursor: pointer;
}
</style>